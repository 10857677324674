/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material UI */
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* Components */
import { Sidebar } from "../../../components/app/Sidebar";
import { BoxPaper } from "../../../components/app/Box";
import { Scrollbar } from "../../../components/app/Scrollbar";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

/* Chart.js */
import { HorizontalBar, Pie } from "react-chartjs-2";
import { getLogs } from "./util";

export const Logs = () => {
  const [data, setData] = useState(null);

  const load = useCallback(async () => {
    try {
      let unmounted = false;

      (async function () {
        try {
          const response = await getLogs();
          if (!unmounted) {
            setData(response);
          }
        } catch (error) {
          setData(null);
          console.error("Error loading data:", error);
        }
      })();

      return () => {
        unmounted = true;
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  const generateColors = (count) => {
    const colors = [];
    const hueStep = 360 / count; // Dividir o círculo de cor pelo número de cores

    for (let i = 0; i < count; i++) {
      // Ajuste para cores mais agradáveis
      const hue = i * hueStep;
      const saturation = 65; // Saturação média-alta para cores mais vibrantes
      const lightness = 55; // Luminosidade média para melhor visibilidade

      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }

    return colors;
  };

  if (!data) {
    return (
      <Sidebar>
        <Box px={4}>
          <BoxPaper>
            <Box minHeight="100vh">
              <Scrollbar>
                <Box textAlign="center" mt={4} mb={5} px={2}>
                  <AutoStoriesIcon fontSize="large" color="secondary" />
                  <Typography
                    component="div"
                    variant="h6"
                    fontWeight={600}
                    color="secondary"
                    mt={1}
                  >
                    Carregando dados...
                    <Box mt={4}>
                      ATENÇÃO: Devido à quantidade de dados, o carregamento pode
                      ser lento.
                    </Box>
                    <Box mt={4}>EVITE RECARREGAR ESTA PÁGINA!</Box>
                  </Typography>
                </Box>
              </Scrollbar>
            </Box>
          </BoxPaper>
        </Box>
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <Box px={4}>
        <BoxPaper>
          <Box minHeight="100vh">
            <Scrollbar>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">
                    Distribuição de Referências
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" mb={2}>
                    Apresenta a distribuição de visitas agrupadas pela
                    referência utilizada (códigos ou identificadores).
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Pie
                        data={{
                          labels: data.refCount.map(
                            (item) => item._id || "Sem Referência"
                          ),
                          datasets: [
                            {
                              label: "Referências",
                              data: data.refCount.map((item) => item.count),
                              backgroundColor: generateColors(
                                data.refCount.length
                              ),
                            },
                          ],
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Referência</TableCell>
                              <TableCell>Visitas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.refCount.map((ref, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {ref._id || "Sem Referência"}
                                </TableCell>
                                <TableCell>{ref.count} </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">
                    Distribuição de Fontes UTM
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" mb={2}>
                    Apresenta a distribuição de visitas agrupadas pela fonte
                    UTM, que indica de onde o usuário foi direcionado.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Pie
                        data={{
                          labels: data.utmSourceVisits.map(
                            (item) => item._id || "Sem Fonte UTM"
                          ),
                          datasets: [
                            {
                              label: "Fontes UTM",
                              data: data.utmSourceVisits.map(
                                (item) => item.count
                              ),
                              backgroundColor: generateColors(
                                data.utmSourceVisits.length
                              ),
                            },
                          ],
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Fonte UTM</TableCell>
                              <TableCell>Visitas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.utmSourceVisits.map((utmSource, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {utmSource._id || "Sem Fonte UTM"}
                                </TableCell>
                                <TableCell>{utmSource.count}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">
                    Distribuição de Dispositivos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" mb={2}>
                    Apresenta a distribuição de visitas agrupadas pelo tipo de
                    dispositivo utilizado.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Pie
                        data={{
                          labels: data.deviceVisits.map((item) => item._id),
                          datasets: [
                            {
                              label: "Dispositivos",
                              data: data.deviceVisits.map((item) => item.count),
                              backgroundColor: generateColors(
                                data.deviceVisits.length
                              ),
                            },
                          ],
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Dispositivo</TableCell>
                              <TableCell>Visitas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.deviceVisits.map((device, index) => (
                              <TableRow key={index}>
                                <TableCell>{device._id}</TableCell>
                                <TableCell>{device.count}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box>
                    <Typography
                      fontWeight={600}
                      color="secondary"
                      mb={1}
                      display="block"
                    >
                      TOP 10
                    </Typography>

                    <Typography variant="h5" display="block">
                      Origem das Visitas por Referência
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" mb={2}>
                    Este gráfico mostra as páginas de outros sites ou
                    plataformas de onde os visitantes vieram antes de chegar a
                    este site. Em outras palavras, ele representa os links ou
                    referências mais comuns que direcionaram usuários para cá,
                    permitindo entender quais sites ou redes sociais geram mais
                    tráfego para este site.
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Horizontal Bar Chart */}
                    <Grid item xs={12}>
                      <HorizontalBar
                        data={{
                          labels: data.refererVisits.map((item) => item._id),
                          datasets: [
                            {
                              label: "Referer",
                              data: data.refererVisits.map(
                                (item) => item.count
                              ),
                              backgroundColor: generateColors(
                                data.refererVisits.length
                              ),
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </Grid>

                    {/* Data Table */}
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Referer</TableCell>
                              <TableCell>Visitas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.refererVisits.map((referer, index) => (
                              <TableRow key={index}>
                                <TableCell>{referer._id}</TableCell>
                                <TableCell>{referer.count}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box>
                    <Typography
                      fontWeight={600}
                      color="secondary"
                      mb={1}
                      display="block"
                    >
                      TOP 10
                    </Typography>

                    <Typography variant="h5" display="block">
                      Origem das Visitas por URL
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" mb={2}>
                    Este gráfico mostra as páginas específicas dentro deste site
                    que receberam mais visitas. Cada URL representa uma página
                    específica que foi acessada por visitantes, permitindo
                    entender quais áreas do site geram mais tráfego.
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Horizontal Bar Chart */}
                    <Grid item xs={12}>
                      <HorizontalBar
                        data={{
                          labels: data.urlCount.map(
                            (item) => item._id || "Sem URL"
                          ),
                          datasets: [
                            {
                              label: "URL",
                              data: data.urlCount.map((item) => item.count),
                              backgroundColor: generateColors(
                                data.urlCount.length
                              ),
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </Grid>

                    {/* Data Table */}
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  maxWidth: "300px",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                }}
                              >
                                URL
                              </TableCell>
                              <TableCell>Visitas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.urlCount.map((url, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    maxWidth: "300px",
                                    wordBreak: "break-all",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {url._id || "Sem URL"}
                                </TableCell>
                                <TableCell>{url.count}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Scrollbar>
          </Box>
        </BoxPaper>
      </Box>
    </Sidebar>
  );
};
