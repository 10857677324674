import { admin } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await admin.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

// export const QtdUser = async () => {
//   try {
//     // let response = await api.get("");

//     // const data = response.data;

//     // return data;
//     return null;
//   } catch (error) {
//     return error;
//   }
// };
