/* React */
import React, { useState, useEffect } from "react";

/* Material */
import {
  Box,
  Button,
  Typography,
  TableRow as MuiTableRow,
} from "@mui/material";

/* Icons */
import FlagIcon from "@mui/icons-material/Flag";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import {
  classesTable,
  StylesMuiTableCell as MuiTableCellBody,
} from "../../../../../components/app/Table";

// functions
const formatDate = (createdAt) => {
  const createdDate = new Date(
    new Date(createdAt).getTime() - 3 * 60 * 60 * 1000
  );

  let dateString = new Date(createdDate)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

const CalcularTempoRestante = ({ createdAt, hoursLimit }) => {
  const [tempoRestante, setTempoRestante] = useState("");

  useEffect(() => {
    const calcularTempo = () => {
      const createdDate = new Date(
        new Date(createdAt).getTime() - 3 * 60 * 60 * 1000
      );
      const currentDate = new Date();

      // Calcular o tempo limite em milissegundos
      const hoursLimitMs = hoursLimit * 60 * 60 * 1000;

      // Calcular a diferença de tempo entre a data atual e a data de criação
      const timeDifference = currentDate - createdDate;

      // Verificar se o tempo já passou
      if (timeDifference > hoursLimitMs) {
        setTempoRestante("Atrasado");
      } else {
        // Calcular quanto tempo resta em horas, minutos e segundos
        const timeRemainingMs = hoursLimitMs - timeDifference;

        const hoursRemaining = Math.floor(timeRemainingMs / (1000 * 60 * 60));
        const minutesRemaining = Math.floor(
          (timeRemainingMs % (1000 * 60 * 60)) / (1000 * 60)
        );
        const secondsRemaining = Math.floor(
          (timeRemainingMs % (1000 * 60)) / 1000
        );

        setTempoRestante(
          `${hoursRemaining}:${minutesRemaining}:${secondsRemaining}`
        );
      }
    };

    // Calcular tempo restante inicialmente
    calcularTempo();

    // Atualizar a cada segundo
    const interval = setInterval(calcularTempo, 1000);

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, [createdAt, hoursLimit]);

  return <p>{tempoRestante}</p>;
};

export const TableRow = (props) => {
  // props
  const { row, openDrawer } = props;

  // functions
  function useHover() {
    const [hovering, setHovering] = useState(false);

    const onHoverProps = {
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
    };

    return [hovering, onHoverProps];
  }

  // states
  const [tdIsHovering, tdHoverProps] = useHover();

  return (
    <MuiTableRow
      {...tdHoverProps}
      onClick={openDrawer}
      sx={[
        {
          "&:hover td div.table-containerCellActive, &:hover td div.table-containerCell":
            {
              backgroundColor: (theme) => theme.palette.table.selected,
            },
        },
      ]}
    >
      {/* Título */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
        >
          {tdIsHovering ? (
            <Box
              sx={{
                marginRight: 1.75,
                height: "50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: (theme) => theme.spacing(0.75),
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="button"
                endIcon={<ArrowForwardIcon />}
              >
                Editar
              </Button>
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <Box component="div" className={classesTable.containerCellActive}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "280px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {row.title}
            </Typography>
          </Box>
        </Box>
      </MuiTableCellBody>

      {/* Autor */}
      {/* <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "left",
            width: 100,
          }}
        >
          <Typography
            sx={{
              width: 90,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "block",
              overflow: "hidden",
            }}
          >
            {row.user_name}
          </Typography>
        </Box>
      </MuiTableCellBody> */}

      {/* Nota */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 90,
          }}
        >
          {row.corrected ? row.essay_average_grade : "--"}
        </Box>
      </MuiTableCellBody>

      {/* Corrigido */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 80,
          }}
        >
          {row.corrected ? (
            <CheckIcon
              sx={{
                fontSize: "11pt",
                color: (theme) => theme.palette.success.main,
              }}
            />
          ) : (
            <CloseIcon
              sx={{
                fontSize: "11pt",
              }}
            />
          )}
        </Box>
      </MuiTableCellBody>

      {/* Proriodade */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            width: 80,
          }}
        >
          <FlagIcon
            color={row.submission_type === "express" ? "warning" : "info"}
            sx={{
              fontSize: "20px",
              display: "block",
            }}
          />
          <Typography
            sx={{
              marginTop: "4px",
              fontSize: "10px",
              color: (theme) =>
                row.submission_type === "express"
                  ? theme.palette.warning.main
                  : theme.palette.info.main,
            }}
          >
            {row.submission_type === "express" ? "Expressa" : "Normal"}
          </Typography>
        </Box>
      </MuiTableCellBody>

      {/* Corrigir até */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 140,
          }}
        >
          {!row.corrected ? (
            <Typography
              sx={{
                color: (theme) => theme.palette.error.main,
                fontSize: "11pt",
              }}
            >
              <CalcularTempoRestante
                createdAt={row.created_at}
                hoursLimit={row.submission_type === "express" ? 12 : 24}
              />
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "11pt",
                fontWeight: "bold",
                color: (theme) => theme.palette.success.main,
              }}
            >
              Corrigida
            </Typography>
          )}
        </Box>
      </MuiTableCellBody>

      {/* Inscrição */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 140,
          }}
        >
          {formatDate(row.created_at)}
        </Box>
      </MuiTableCellBody>
    </MuiTableRow>
  );
};
