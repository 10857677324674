export const getToken = () => {
  const json = localStorage.getItem("us-token");
  const user = JSON.parse(json);

  if (user) {
    const { authorization } = user;

    if (authorization) {
      // User token
      const { token } = authorization;

      return token;
    } else {
      // authorization empty
      return null;
    }
  } else {
    // user empty
    return null;
  }
};
