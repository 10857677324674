/* React */
import React, { useEffect } from "react";

/* Router */
import { useNavigate } from "react-router-dom";

/* Contexts */
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const Logout = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
    navigate("/login");
  }, [auth, navigate]);

  return <React.Fragment></React.Fragment>;
};
