/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material UI */
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* Components */
import { Sidebar } from "../../../components/app/Sidebar";
import { BoxPaper } from "../../../components/app/Box";
import { Scrollbar } from "../../../components/app/Scrollbar";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

/* Chart.js */
import { HorizontalBar, Pie } from "react-chartjs-2";
import { getLogs } from "./util";

export const LogsList = () => {
  const [data, setData] = useState(null);

  const load = useCallback(async () => {
    try {
      let unmounted = false;

      (async function () {
        try {
          const response = await getLogs();
          if (!unmounted) {
            setData(response);
          }
        } catch (error) {
          setData(null);
          console.error("Error loading data:", error);
        }
      })();

      return () => {
        unmounted = true;
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  // functions
  const formatDate = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  if (!data) {
    return (
      <Sidebar>
        <Box px={4}>
          <BoxPaper>
            <Box minHeight="100vh">
              <Scrollbar>
                <Box textAlign="center" mt={4} mb={5} px={2}>
                  <AutoStoriesIcon fontSize="large" color="secondary" />
                  <Typography
                    component="div"
                    variant="h6"
                    fontWeight={600}
                    color="secondary"
                    mt={1}
                  >
                    Carregando dados...
                    <Box mt={4}>
                      ATENÇÃO: Devido à quantidade de dados, o carregamento pode
                      ser lento.
                    </Box>
                    <Box mt={4}>EVITE RECARREGAR ESTA PÁGINA!</Box>
                  </Typography>
                </Box>
              </Scrollbar>
            </Box>
          </BoxPaper>
        </Box>
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <Box px={4}>
        <BoxPaper>
          <Box minHeight="100vh">
            <Scrollbar>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">
                    Tabela de Resultados Detalhados
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" mb={2}>
                    Exibe uma lista completa de todas as visitas registradas.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  maxWidth: "300px",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                }}
                              >
                                URL
                              </TableCell>
                              <TableCell>Referência</TableCell>
                              <TableCell>Fonte UTM</TableCell>
                              <TableCell
                                sx={{
                                  maxWidth: "300px",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                }}
                              >
                                Referer
                              </TableCell>
                              <TableCell>Dispositivo</TableCell>
                              <TableCell>UID</TableCell>
                              <TableCell>Data e Hora</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.result.map((log, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    maxWidth: "300px",
                                    wordBreak: "break-all",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {log.url || "--"}
                                </TableCell>
                                <TableCell>{log.ref || "--"}</TableCell>
                                <TableCell>{log.utm_source || "--"}</TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: "300px",
                                    wordBreak: "break-all",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {log.referer || "--"}
                                </TableCell>
                                <TableCell>{log.device || "--"}</TableCell>
                                <TableCell>{log.uid || "--"}</TableCell>
                                <TableCell>
                                  {formatDate(log.datetime) || "--"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Scrollbar>
          </Box>
        </BoxPaper>
      </Box>
    </Sidebar>
  );
};
