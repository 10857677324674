/* React */
import React, { useState } from "react";

/* Material */
import { Grid, Box, Button, Divider } from "@mui/material";

/* Icons */
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import FormControl from "../../../../../../components/app/Form/FormControl";
import useForm from "../../../../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { SubTitlePage } from "../../../../../../components/app/Title";
import { Alert } from "../../../../../../components/app/Alert";

/* Functions */
import { CreateUser } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";

const initialValues = {
  nome: "",
  email: "",
  login: "",
  senha: Math.random().toString(36).substring(2),
  permissao: "1",
};

export const DrawerAdd = (props) => {
  // props
  const { open, setOpen } = props;

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const isEmpty = (value) => {
    const result = value ? "" : "Preencha este campo.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("nome" in fieldValues) {
      temp.nome = isEmpty(fieldValues.nome);
    }

    if ("login" in fieldValues) {
      temp.login = isEmpty(fieldValues.login);
    }

    if ("senha" in fieldValues) {
      temp.senha = isEmpty(fieldValues.senha);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          await CreateUser(
            values.nome,
            values.email,
            values.login,
            values.senha,
            values.permissao
          )
            .catch((error) => {
              console.log(error);
              setMessageError("Ocorreu um erro ao salvar.");
              setOpenError(true);
            })
            .finally(() => {
              goList();
            });
        } else {
          setMessageError("Por favor, corrija os campos em vermelho.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("Ocorreu um erro inesperado na sessão.");
        setOpenError(true);
      }
    }, 100);
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const goList = () => {
    window.location.reload();
  };

  const randomPassword = () => {
    let name = "senha";

    setValues({
      ...values,
      [name]: Math.random().toString(36).substring(2),
    });
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
            marginBottom: (theme) => theme.spacing(1.5),
          }}
        />

        <Box px={4} py={2}>
          <Box mb={4}>
            <SubTitlePage>Novo Usuário</SubTitlePage>

            <FormControl onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Nome completo"
                    name="nome"
                    value={values.nome}
                    error={errors.nome}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="Login de acesso"
                    name="login"
                    value={values.login}
                    error={errors.login}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="E-mail de acesso (opcional)"
                    name="email"
                    value={values.email}
                    error={errors.email}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      <Controls.Input
                        label="Senha de acesso"
                        type="text"
                        name="senha"
                        value={values.senha}
                        error={errors.senha}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                      />
                    </Box>
                    <Box mx={2} mb={"0.75rem"}>
                      <IconButton onClick={randomPassword} type="button">
                        <ReplayIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Controls.Select
                    label="Permissão"
                    name="permissao"
                    nullValue={false}
                    items={[
                      { title: "Leitura", value: "0" },
                      { title: "Escrita", value: "1" },
                      { title: "Execução", value: "2" },
                    ]}
                    value={values.permissao}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  {openError ? (
                    <Box mb={2}>
                      <Alert message={messageError} open={openError} />
                    </Box>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  <Controls.Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    width="100%"
                    text="Salvar"
                    endIcon={<ArrowForwardIcon />}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
