import { admin, redgiz } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await admin.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const ListRedacoes = async (
  skip = 0,
  limit = 10,
  search = "",
  order_by = "created_at",
  order_direction = "desc"
) => {
  try {
    let response = await redgiz.get(
      `admin/essays?limit=${limit}&skip=${skip}&search=${search}&order_by=${order_by}&order_direction=${order_direction}`
    );

    const data = response.data;

    if (data) {
      return { result: data[0], qtd: data[1] };
    }

    return { result: [], qtd: 0 };
  } catch (error) {
    return error;
  }
};

export const ListSupportingTexts = async (
  id,
  skip = 0,
  limit = 10,
  search = "",
  order_by = "created_at",
  order_direction = "desc"
) => {
  try {
    let response = await redgiz.get(
      `admin/essay_templates/${id}/supporting-texts?limit=${limit}&skip=${skip}&search=${search}&order_by=${order_by}&order_direction=${order_direction}`
    );

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateSupportingTexts = async (
  content,
  essay_template_id,
  title,
  external_url,
  type,
  reference
) => {
  try {
    let response = await redgiz.post(`admin/supporting_texts/`, {
      content,
      essay_template_id,
      title,
      external_url,
      type,
      reference,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const Show = async (id) => {
  try {
    let response = await redgiz.get(`admin/feedback/${id}`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateRedacoes = async (title) => {
  try {
    let response = await redgiz.post("admin/examining_body/", {
      title,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateFeedback = async (
  id,
  general_comment,
  essay_average_grade,
  audio
) => {
  try {
    let response = await redgiz.patch(`admin/feedback/${id}`, {
      general_comment,
      essay_average_grade: parseInt(essay_average_grade),
      audio,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateGrade = async (
  id,
  id_grade,
  grade,
  feedback,
  recommendation,
  audio
) => {
  try {
    let response = await redgiz.patch(`admin/item_grade/${id_grade}`, {
      id: id,
      grade: parseInt(grade),
      feedback,
      recommendation,
      audio,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateCheckEssay = async (id) => {
  try {
    let response = await redgiz.patch(`admin/essay-submission/${id}/correct`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateGradeIA = async (id) => {
  try {
    let response = await redgiz.put(`admin/essays/${id}/auto_correct`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateRedacoes = async (id, title) => {
  try {
    let response = await redgiz.put(`admin/examining_body/${id}`, {
      title,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const DeleteRedacoes = async (id) => {
  try {
    let response = await redgiz.delete(`admin/essays/${id}`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
