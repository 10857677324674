/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Paper,
  Button,
  TableContainer,
  Table,
  Link,
  TableBody,
  Divider,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

/* Icons */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import { Show, StudioUpdatePlan } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

const formatPhone = (phone) => {
  phone = phone
    ? `https://api.whatsapp.com/send?phone=55${phone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "")}`
    : "";
  return phone;
};

export const DrawerUser = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  // states
  const [data, setData] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  // functions
  const formatDate = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setData(null);

      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              if (!unmounted) {
                setData(response);
              }
            })
            .catch((error) => {
              console.log(error);
              if (!unmounted) {
                setMessageError(error.message);
                setOpenError(true);
              }
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);

      if (!unmounted) {
        setMessageError(error.message);
        setOpenError(true);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && open && id) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        {data ? (
          <Box
            sx={{
              color: "#ffffff",
              textAlign: "center",
              backgroundColor: (theme) =>
                `${
                  data.active
                    ? `${theme.palette.success.main} !important`
                    : `${theme.palette.error.main} !important`
                }`,
            }}
          >
            <Typography component="p" py={0.5}>
              {data.active ? "Conta ativa" : "Conta desativada"}
            </Typography>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {data ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <SubTitlePage>{data.name}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* Dados pessoais */}
              {/* <Box mb={4}>
                <SubTitlePage>Dados pessoais</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {data.user_id}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nome completo
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {data.name}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          E-mail
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {data.email}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Data de Nasc.
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {data.birth_date ? data.birth_date : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

              {/* Contato */}
              {/* <Box mb={4}>
                <SubTitlePage>Contato</SubTitlePage>
                <TableContainer component={Paper} mb={4}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Telefone
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {data.phone ? (
                            <Link
                              href={formatPhone(data.phone)}
                              target="_blank"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <WhatsAppIcon sx={{ height: 15 }} />
                              {data.phone}
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box> */}

              {/* Informação do Usuário */}
              <Box mb={4}>
                <SubTitlePage>Informações do Usuário</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.user_id}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nome
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.name}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Sobrenome
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.surname || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Profissão
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.profession || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Email
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.email}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Email Confirmado
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.email_confirmed ? "Sim" : "Não"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Telefone
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.phone ? (
                            <Link
                              href={formatPhone(data.phone)}
                              target="_blank"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <WhatsAppIcon sx={{ height: 15 }} />
                              {data.phone}
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Data de Criação
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {formatDate(data.created_at)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Última Atualização
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {formatDate(data.created_at)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Dados de Acesso */}
              <Box mb={4}>
                <SubTitlePage>Dados de Acesso</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nível de Acesso
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.access_level || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Plano Studio
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          <Select
                            value={data.st_current_signature_type || ""}
                            // onChange={(event) => {
                            //   const newValue =
                            //     event.target.value === ""
                            //       ? null
                            //       : event.target.value;
                            //   setData((prevData) => ({
                            //     ...prevData,
                            //     st_current_signature_type: newValue,
                            //   }));
                            // }}

                            onChange={async (event) => {
                              const newValue =
                                event.target.value === ""
                                  ? null
                                  : event.target.value;

                              const email = data.email;

                              try {
                                const result = await StudioUpdatePlan(
                                  email,
                                  newValue
                                );

                                if (
                                  result &&
                                  result.st_current_signature_type
                                ) {
                                  setData((prevData) => ({
                                    ...prevData,
                                    st_current_signature_type: newValue,
                                  }));

                                  alert("Plano atualizado com sucesso!");
                                } else {
                                  alert("Erro ao atualizar o plano.");
                                }
                              } catch (error) {
                                alert(
                                  "Erro ao atualizar o plano. Por favor, tente novamente mais tarde."
                                );
                              }
                            }}
                          >
                            <MenuItem value="starter">Starter</MenuItem>
                            <MenuItem value="pro">Pro</MenuItem>
                            <MenuItem value="enterprise">Enterprise</MenuItem>
                            <MenuItem value="free">Free</MenuItem>
                          </Select>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Plano Aluno
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {" "}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={data.pl_signature_active === true}
                                onChange={(event) => {
                                  const newValue = event.target.checked
                                    ? true
                                    : false;
                                  setData((prevData) => ({
                                    ...prevData,
                                    pl_signature_active: newValue,
                                  }));
                                }}
                                color="primary"
                              />
                            }
                            label=""
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Dados Adicionais */}
              <Box mb={4}>
                <SubTitlePage>Dados Adicionais</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          REF
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.first_ref || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Dispositivo
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.device || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          URL
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.url || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          UTM
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.utm_source || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          URL Anterior
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.previous_url || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Social
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.social || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Cupom */}
              <Box mb={4}>
                <SubTitlePage>Cupom</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Criação do Cupom
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.cupom_created_at || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nome do Cupom
                        </StyledTableCellTitle>
                        <StyledTableCell align="right">
                          {data.cupom_name || "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box my={5} textAlign="center">
              <CircularProgress color="secondary" />
            </Box>
          </React.Fragment>
        )}
      </Drawer>
    </React.Fragment>
  );
};
