import React from "react";

import "./App.css";

/* Context */
import { AppThemeProvider } from "./contexts/ThemeProvider";
import { AppAuthProvider } from "./contexts/AuthProvider";

/* Routes */
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes/App";

export const App = () => {
  return (
    <AppThemeProvider>
      <AppAuthProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AppAuthProvider>
    </AppThemeProvider>
  );
};
