/* React */
import React from "react";

/* Material */
import { Button as MuiButton } from "@mui/material";

export function Button(props) {
  const {
    text,
    size,
    color,
    variant,
    type,
    action,
    width,
    textTransform,
    margin,
    fontSize,
    startIcon,
    endIcon,
  } = props;

  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      type={type ? type : "submit"}
      onClick={action ? action : () => {}}
      startIcon={startIcon ? startIcon : <React.Fragment></React.Fragment>}
      endIcon={endIcon ? endIcon : <React.Fragment></React.Fragment>}
      style={{
        minHeight: "46px",
        width: width ? width : "auto",
        textTransform: textTransform ? textTransform : "uppercase",
        margin: margin ? margin : "0.15rem",
        fontSize: fontSize ? fontSize : "10pt",
      }}
    >
      {text}
    </MuiButton>
  );
}
