/* React */
import React, { useState, useEffect } from "react";

/* Material */
import {
  Box,
  Typography,
  TextField,
  Chip,
  Button,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab/";

/* Icons */
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

/* Components */
import { Sidebar } from "../../../components/app/Sidebar";
import { BoxPaper } from "../../../components/app/Box";
import { TitlePage } from "../../../components/app/Title";
import { Alert } from "../../../components/app/Alert";

/* Util */
import { ListUser, SendEmail } from "./util";

/* Styles */
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

/* Functions */
function isEmail(email) {
  let reg = /^[\w.\+]+@\w+.\w{2,}(?:.\w{2})?$/;
  return reg.test(email);
}

export const DirectMail = () => {
  // states
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [email, setEmail] = useState("");
  const [successEmail, setSuccessEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    if (errorEmail) setErrorEmail(false);
  };

  const addEmailToList = (event) => {
    let newEmail = event.target.value;
    let id = Math.random().toString(36).substring(2) + Date.now();

    if (isEmail(newEmail)) {
      setTo([
        ...to,
        {
          id,
          email: newEmail,
        },
      ]);
      setEmail("");
    } else {
      setErrorEmail(true);
    }
  };

  const removeEmailToList = (id) => {
    let list = to;

    list = list.filter(function (item) {
      return item.id !== id;
    });

    setTo(list);
  };

  const discardEmails = () => {
    setTo([]);
  };

  const discardDraft = () => {
    setEmail("");

    setTo([]);
    setSubject("");
    setMessage("");
  };

  const handleSubmit = () => {
    setBtnLoading(true);

    setTimeout(() => {
      (async function () {
        try {
          await SendEmail(to, subject, message).then((response) => {
            setBtnLoading(false);
            setSuccessEmail(true);

            discardDraft();
          });
        } catch (error) {
          console.log(error);

          setMessageError(error.message);
          setOpenError(true);
        }
      })();
    }, 500);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => setSuccessEmail(!successEmail)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    let unmounted = false;

    (async function () {
      try {
        await ListUser().then((response) => {
          const { result } = response;

          if (!unmounted) {
            setTo(
              result.map((item) => ({
                email: item.email,
                id: item.user_id,
              }))
            );
          }
        });
      } catch (error) {
        console.log(error);

        setMessageError(error.message);
        setOpenError(true);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Sidebar>
      <Box px={4}>
        <BoxPaper>
          <Box minHeight="100vh">
            <TitlePage>Mala direta</TitlePage>

            <Alert message={messageError} open={openError} />

            <Box my={5}>
              <Box mb={"1.25rem"}>
                <TextField
                  label="Adicionar Destinatário"
                  fullWidth
                  autoComplete="off"
                  value={email}
                  onChange={handleChangeEmail}
                  error={errorEmail}
                  helperText={
                    errorEmail
                      ? "E-mail inválido"
                      : "Pressione ENTER, vírgula ou espaço para adicionar"
                  }
                  onBlur={(event) => {
                    event.preventDefault();
                    addEmailToList(event);
                  }}
                  onKeyPress={(event) => {
                    if (
                      event.key === "Enter" ||
                      event.key === "space" ||
                      event.key === ","
                    ) {
                      event.preventDefault();
                      addEmailToList(event);
                    }
                  }}
                />
              </Box>

              {to.length > 0 ? (
                <React.Fragment>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    my={4}
                  >
                    <Typography mr={1}>Total de:</Typography>

                    <Chip
                      label={`${to.length} e-mails adicionados.`}
                      onDelete={discardEmails}
                      deleteIcon={<DeleteIcon />}
                      variant="outlined"
                    />
                  </Box>

                  <Box mb={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                    >
                      {to.map(function (item, index) {
                        return (
                          <ListItem key={index}>
                            <Chip
                              label={item.email}
                              onDelete={() => removeEmailToList(item.id)}
                              deleteIcon={<CancelIcon />}
                            />
                          </ListItem>
                        );
                      })}
                    </Box>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              <Box mb={"1.25rem"}>
                <TextField
                  label="Assunto"
                  fullWidth
                  autoComplete="off"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />
              </Box>

              <Box mb={"1.25rem"}>
                <TextField
                  label="Mensagem"
                  fullWidth
                  autoComplete="off"
                  multiline
                  rows={15}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
              </Box>

              <Box
                mb={"1.25rem"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {to.length > 0 ? (
                  subject !== "" ? (
                    message !== "" ? (
                      btnLoading === true ? (
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="outlined"
                        >
                          Aguarde...
                        </LoadingButton>
                      ) : (
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          endIcon={<SendIcon />}
                          sx={{
                            minWidth: "130px",
                            paddingTop: 1.15,
                            paddingBottom: 1.15,
                          }}
                        >
                          Enviar
                        </Button>
                      )
                    ) : (
                      <Typography component="div" variant="body1">
                        Adicione uma mensagem.
                      </Typography>
                    )
                  ) : (
                    <Typography component="div" variant="body1">
                      Adicione um assunto para enviar uma mensagem.
                    </Typography>
                  )
                ) : (
                  <Typography component="div" variant="body1">
                    Adicione pelo menos 1 e-mail para enviar uma mensagem.
                  </Typography>
                )}

                <Box flexGrow="1"></Box>

                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={discardDraft}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </BoxPaper>
      </Box>

      <Snackbar
        open={successEmail}
        onClose={() => setSuccessEmail(!successEmail)}
        autoHideDuration={8000}
        action={action}
        message="E-mail enviado com sucesso!"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Sidebar>
  );
};
