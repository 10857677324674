/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material ui */
import {
  Box,
  TextField,
  Divider,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Button,
  Alert as MuiAlert,
} from "@mui/material";

/* Icons */
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/* Components */
import { Alert } from "../../../../../components/app/Alert";
import { CardTitle } from "../../../../../components/shared/Card/Main";

/* Services */
import { getUser, UpdateUserInfo, UpdateUserSecure } from "../../util";

export const MyAccount = () => {
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showAlertInfo, setShowAlertInfo] = useState(false);
  const [showAlertSecure, setShowAlertSecure] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const load = useCallback(async () => {
    try {
      let unmounted = false;

      (async function () {
        try {
          await getUser().then((response) => {
            let { result } = response;

            if (!unmounted) {
              setId(result.id);
              setNome(result.nome);
              setEmail(result.email);
              setLogin(result.login);
            }
          });
        } catch (error) {
          return null;
        }
      })();

      return () => {
        unmounted = true;
      };
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  const handleChangeNome = (event) => {
    const value = event.target.value;

    setNome(value);
  };

  const handleChangeEmail = (event) => {
    const value = event.target.value;

    setEmail(value);
  };

  const handleChangeLogin = (event) => {
    const value = event.target.value;

    setLogin(value);
  };

  const handleChangePassword = (event) => {
    const value = event.target.value;

    setPassword(value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const editInfo = async () => {
    setTimeout(() => {
      setShowAlertInfo(true);
    }, 500);

    await UpdateUserInfo(nome, email, login, id)
      .then((data) => {
        setTimeout(() => {
          setShowAlertInfo(false);
        }, 8000);

        return null;
      })
      .catch((error) => {
        setTimeout(() => {
          setShowAlertInfo(false);
        }, 8000);

        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      });
  };

  const editSecure = async () => {
    setTimeout(() => {
      setShowAlertSecure(true);
    }, 500);

    await UpdateUserSecure(password, id)
      .then((data) => {
        setTimeout(() => {
          setShowAlertSecure(false);
        }, 8000);

        return null;
      })
      .catch((error) => {
        setTimeout(() => {
          setShowAlertSecure(false);
        }, 8000);

        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      });
  };

  return (
    <Box my={3}>
      <CardTitle
        title="Configurações de Conta"
        description="Edite suas configurações de conta e altere sua senha aqui."
      >
        <Box my={2}>
          {openError ? (
            <React.Fragment>
              <Box mb={4}>
                <Alert message={messageError} open={openError} />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}

          <Box pb={5}>
            <Typography variant="h6" fontWeight={600} mb={4}>
              Principal
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginBottom: 1.25 }}>
                <TextField
                  fullWidth
                  label="Nome de usuário"
                  placeholder="Nome de usuário"
                  variant="outlined"
                  type="text"
                  value={nome || ""}
                  onChange={handleChangeNome}
                />
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 1.25 }}>
                <TextField
                  fullWidth
                  label="Login de acesso"
                  placeholder="Login de acesso"
                  variant="outlined"
                  type="text"
                  value={login || ""}
                  onChange={handleChangeLogin}
                />
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 1.25 }}>
                <TextField
                  fullWidth
                  label="E-mail de acesso"
                  placeholder="E-mail de acesso"
                  variant="outlined"
                  type="text"
                  value={email || ""}
                  onChange={handleChangeEmail}
                />
              </Grid>

              <Grid item xs={12}>
                {showAlertInfo ? (
                  <Box mb={4}>
                    <MuiAlert
                      severity="success"
                      color="success"
                      variant="filled"
                    >
                      Atualizado com sucesso
                    </MuiAlert>
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <Box my={2} mx={1} textAlign="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editInfo()}
                  >
                    Atualizar
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={600} mb={4}>
                  Segurança
                </Typography>

                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Senha de acesso
                  </InputLabel>

                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handleChangePassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Senha de acesso"
                  />

                  <FormHelperText id="component-helper-text">
                    Por razões de segurança sua senha não aparecerá nesta área.
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {showAlertSecure ? (
                  <Box mb={4}>
                    <MuiAlert
                      severity="success"
                      color="success"
                      variant="filled"
                    >
                      Atualizado com sucesso
                    </MuiAlert>
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <Box my={2} mx={1} textAlign="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editSecure()}
                  >
                    Atualizar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardTitle>
    </Box>
  );
};
