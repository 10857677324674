/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Divider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  Slider,
  Chip,
} from "@mui/material";

/* Icons */
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import {
  Show,
  DeleteRedacoes,
  UpdateRedacoes,
  UpdateFeedback,
  UpdateGrade,
  UpdateCheckEssay,
  UpdateGradeIA,
} from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 150,
    label: "150",
  },
  {
    value: 200,
    label: "200",
  },
];

function valuetext(value) {
  return `${value}`;
}

export const DrawerRedacoes = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  const [redacoes, setRedacoes] = useState(null);

  const [formId, setFormId] = useState("");
  const [formTitle, setFormTitle] = useState("");

  const [formCorrected, setFormCorrected] = useState(false);
  const [formGrade, setFormGrade] = useState(0);
  const [formFeedback, setFormFeedback] = useState("");

  const [formCompetenceId1, setFormCompetenceId1] = useState(0);
  const [formCompetenceGrade1, setFormCompetenceGrade1] = useState(0);
  const [formCompetenceFeedback1, setFormCompetenceFeedback1] = useState(0);
  const [formCompetenceRecommendation1, setFormCompetenceRecommendation1] =
    useState(0);

  const [formCompetenceId2, setFormCompetenceId2] = useState(0);
  const [formCompetenceGrade2, setFormCompetenceGrade2] = useState(0);
  const [formCompetenceFeedback2, setFormCompetenceFeedback2] = useState(0);
  const [formCompetenceRecommendation2, setFormCompetenceRecommendation2] =
    useState(0);

  const [formCompetenceId3, setFormCompetenceId3] = useState(0);
  const [formCompetenceGrade3, setFormCompetenceGrade3] = useState(0);
  const [formCompetenceFeedback3, setFormCompetenceFeedback3] = useState(0);
  const [formCompetenceRecommendation3, setFormCompetenceRecommendation3] =
    useState(0);

  const [formCompetenceId4, setFormCompetenceId4] = useState(0);
  const [formCompetenceGrade4, setFormCompetenceGrade4] = useState(0);
  const [formCompetenceFeedback4, setFormCompetenceFeedback4] = useState(0);
  const [formCompetenceRecommendation4, setFormCompetenceRecommendation4] =
    useState(0);

  const [formCompetenceId5, setFormCompetenceId5] = useState(0);
  const [formCompetenceGrade5, setFormCompetenceGrade5] = useState(0);
  const [formCompetenceFeedback5, setFormCompetenceFeedback5] = useState(0);
  const [formCompetenceRecommendation5, setFormCompetenceRecommendation5] =
    useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const handleClickCloseUDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenUDialog = () => {
    setOpenDialog(true);
  };

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const handleChangeDelete = async () => {
    await DeleteRedacoes(formId)
      .catch((error) => {
        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      })
      .finally(() => {
        goList();
      });
  };

  const handleChangeUpdateIA = async () => {
    if (!formId) {
      alert("Por favor, preencha todos os campos antes de prosseguir.");
      return;
    }

    setRedacoes(null);

    await Promise.all([await UpdateGradeIA(id), UpdateCheckEssay(id)]);

    load();
  };

  const handleChangeUpdate = async () => {
    if (!formId) {
      alert("Por favor, preencha todos os campos antes de prosseguir.");
      return;
    }

    const essay_average_grade =
      parseInt(formCompetenceGrade1) +
      parseInt(formCompetenceGrade2) +
      parseInt(formCompetenceGrade3) +
      parseInt(formCompetenceGrade4) +
      parseInt(formCompetenceGrade5);

    setRedacoes(null);

    await Promise.all([
      await UpdateFeedback(formId, formFeedback, essay_average_grade, false),
      UpdateGrade(
        formId,
        formCompetenceId1,
        formCompetenceGrade1,
        formCompetenceFeedback1,
        formCompetenceRecommendation1,
        null
      ),
      UpdateGrade(
        formId,
        formCompetenceId2,
        formCompetenceGrade2,
        formCompetenceFeedback2,
        formCompetenceRecommendation2,
        null
      ),
      UpdateGrade(
        formId,
        formCompetenceId3,
        formCompetenceGrade3,
        formCompetenceFeedback3,
        formCompetenceRecommendation3,
        null
      ),
      UpdateGrade(
        formId,
        formCompetenceId4,
        formCompetenceGrade4,
        formCompetenceFeedback4,
        formCompetenceRecommendation4,
        null
      ),
      UpdateGrade(
        formId,
        formCompetenceId5,
        formCompetenceGrade5,
        formCompetenceFeedback5,
        formCompetenceRecommendation5,
        null
      ),
      UpdateCheckEssay(id),
    ]);

    load();
  };

  // functions
  const formatDate = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  const goList = () => {
    window.location.reload();
  };

  const getCompetences = (result) => {
    result.map((item) => {
      switch (item.competency_name) {
        case "Competência 1":
          setFormCompetenceId1(item.id);
          setFormCompetenceGrade1(item.grade);
          setFormCompetenceFeedback1(item.feedback);
          setFormCompetenceRecommendation1(item.recommendation);
          break;
        case "Competência 2":
          setFormCompetenceId2(item.id);
          setFormCompetenceGrade2(item.grade);
          setFormCompetenceFeedback2(item.feedback);
          setFormCompetenceRecommendation2(item.recommendation);
          break;
        case "Competência 3":
          setFormCompetenceId3(item.id);
          setFormCompetenceGrade3(item.grade);
          setFormCompetenceFeedback3(item.feedback);
          setFormCompetenceRecommendation3(item.recommendation);
          break;
        case "Competência 4":
          setFormCompetenceId4(item.id);
          setFormCompetenceGrade4(item.grade);
          setFormCompetenceFeedback4(item.feedback);
          setFormCompetenceRecommendation4(item.recommendation);
          break;
        case "Competência 5":
          setFormCompetenceId5(item.id);
          setFormCompetenceGrade5(item.grade);
          setFormCompetenceFeedback5(item.feedback);
          setFormCompetenceRecommendation5(item.recommendation);
          break;
        default:
          break;
      }
    });
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setRedacoes(null);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const result = response;

              if (!unmounted) {
                setRedacoes(result);

                setFormCorrected(result?.corrected);
                setFormGrade(result?.essay_average_grade);
                setFormFeedback(result?.general_comment);

                getCompetences(result?.item_grades);

                setFormId(result.id);
                setFormTitle(result.title);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            maxWidth: "95% !important",
            width: "100%",
            backgroundImage: "none !important",
          },
        }}
      >
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {redacoes ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <Box textAlign="end" mb={4}>
                <Button
                  variant="outlined"
                  onClick={handleClickOpenUDialog}
                  type="button"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </Box>

              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <SubTitlePage>{redacoes.essay_title}</SubTitlePage>

                  <Divider
                    sx={{
                      marginBottom: 4,
                    }}
                  />

                  {/* Informações */}
                  <Box mb={4}>
                    <SubTitlePage>Redação:</SubTitlePage>

                    <Box
                      my={4}
                      p={2}
                      sx={{
                        borderRadius: 4,
                        backgroundColor: (theme) =>
                          theme.palette.background.default,
                      }}
                    >
                      <Box
                        component={Paper}
                        elevation={4}
                        p={4}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: (theme) =>
                            theme.palette.background.paper,
                        }}
                      >
                        <Typography sx={{ textAlign: "justify" }}>
                          {redacoes.content}
                        </Typography>
                      </Box>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }}>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              ID
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              {redacoes.id}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Título
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              {redacoes.essay_title}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Corrigido
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              {redacoes.corrected ? (
                                <CheckIcon color="success" />
                              ) : (
                                <CloseIcon color="error" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Prioridade
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Typography>Normal</Typography>
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              Cadastrado em
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              {formatDate(redacoes.created_at)}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SubTitlePage>Correção</SubTitlePage>

                  <Divider
                    sx={{
                      marginBottom: 4,
                    }}
                  />
                  <SubTitlePage>
                    {redacoes.corrected ? (
                      <Chip label="Corrigido" color="success" size="small" />
                    ) : (
                      <Chip label="Não corrigido" color="error" size="small" />
                    )}
                  </SubTitlePage>

                  {/* Informações */}
                  <Box mb={4}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }}>
                        <TableBody>
                          {/* Competencia 1 */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Competência 1
                              <Box maxWidth="100px">
                                <Typography sx={{ fontSize: "12px" }} mt={2}>
                                  Domínio da norma culta da língua portuguesa
                                </Typography>
                              </Box>
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Box textAlign="left">
                                <Typography mb={6}>
                                  Selecione uma nota de 0 a 200:
                                </Typography>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "280px" }}>
                                    <Slider
                                      value={formCompetenceGrade1}
                                      onChange={(event, newValue) =>
                                        setFormCompetenceGrade1(newValue)
                                      }
                                      aria-label="Always visible"
                                      defaultValue={0}
                                      getAriaValueText={valuetext}
                                      step={1}
                                      marks={marks}
                                      min={0}
                                      max={200}
                                      valueLabelDisplay="on"
                                    />
                                  </Box>
                                  <TextField
                                    sx={{
                                      marginLeft: 6,
                                      width: "80px",
                                    }}
                                    value={formCompetenceGrade1}
                                    onChange={(event) =>
                                      setFormCompetenceGrade1(
                                        event.target.value
                                      )
                                    }
                                    type="number"
                                    fullWidth
                                    margin="none"
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <TextField
                                  label="Feedback"
                                  value={formCompetenceFeedback1}
                                  onChange={(event) =>
                                    setFormCompetenceFeedback1(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um feedback"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                              <Box>
                                <TextField
                                  label="Recomendação"
                                  value={formCompetenceRecommendation1}
                                  onChange={(event) =>
                                    setFormCompetenceRecommendation1(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um recomendação"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Competencia 2 */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Competência 2
                              <Box maxWidth="100px">
                                <Typography sx={{ fontSize: "12px" }} mt={2}>
                                  Compreensão do tema, adequação ao tipo textual
                                  e uso do repertório cultural
                                </Typography>
                              </Box>
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Box textAlign="left">
                                <Typography mb={6}>
                                  Selecione uma nota de 0 a 200:
                                </Typography>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "280px" }}>
                                    <Slider
                                      value={formCompetenceGrade2}
                                      onChange={(event, newValue) =>
                                        setFormCompetenceGrade2(newValue)
                                      }
                                      aria-label="Always visible"
                                      defaultValue={0}
                                      getAriaValueText={valuetext}
                                      step={1}
                                      marks={marks}
                                      min={0}
                                      max={200}
                                      valueLabelDisplay="on"
                                    />
                                  </Box>
                                  <TextField
                                    sx={{
                                      marginLeft: 6,
                                      width: "80px",
                                    }}
                                    value={formCompetenceGrade2}
                                    onChange={(event) =>
                                      setFormCompetenceGrade2(
                                        event.target.value
                                      )
                                    }
                                    type="number"
                                    fullWidth
                                    margin="none"
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <TextField
                                  label="Feedback"
                                  value={formCompetenceFeedback2}
                                  onChange={(event) =>
                                    setFormCompetenceFeedback2(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um feedback"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                              <Box>
                                <TextField
                                  label="Recomendação"
                                  value={formCompetenceRecommendation2}
                                  onChange={(event) =>
                                    setFormCompetenceRecommendation2(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um recomendação"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Competencia 3 */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Competência 3
                              <Box maxWidth="100px">
                                <Typography sx={{ fontSize: "12px" }} mt={2}>
                                  Capacidade de selecionar, interpretar e
                                  relacionar informações para a sustentação de
                                  um ponto de vista sobre o tema
                                </Typography>
                              </Box>
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Box textAlign="left">
                                <Typography mb={6}>
                                  Selecione uma nota de 0 a 200:
                                </Typography>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "280px" }}>
                                    <Slider
                                      value={formCompetenceGrade3}
                                      onChange={(event, newValue) =>
                                        setFormCompetenceGrade3(newValue)
                                      }
                                      aria-label="Always visible"
                                      defaultValue={0}
                                      getAriaValueText={valuetext}
                                      step={1}
                                      marks={marks}
                                      min={0}
                                      max={200}
                                      valueLabelDisplay="on"
                                    />
                                  </Box>
                                  <TextField
                                    sx={{
                                      marginLeft: 6,
                                      width: "80px",
                                    }}
                                    value={formCompetenceGrade3}
                                    onChange={(event) =>
                                      setFormCompetenceGrade3(
                                        event.target.value
                                      )
                                    }
                                    type="number"
                                    fullWidth
                                    margin="none"
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <TextField
                                  label="Feedback"
                                  value={formCompetenceFeedback3}
                                  onChange={(event) =>
                                    setFormCompetenceFeedback3(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um feedback"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                              <Box>
                                <TextField
                                  label="Recomendação"
                                  value={formCompetenceRecommendation3}
                                  onChange={(event) =>
                                    setFormCompetenceRecommendation3(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um recomendação"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Competencia 4 */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Competência 4
                              <Box maxWidth="100px">
                                <Typography sx={{ fontSize: "12px" }} mt={2}>
                                  Uso de conectivos e operadores argumentativos
                                </Typography>
                              </Box>
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Box textAlign="left">
                                <Typography mb={6}>
                                  Selecione uma nota de 0 a 200:
                                </Typography>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "280px" }}>
                                    <Slider
                                      value={formCompetenceGrade4}
                                      onChange={(event, newValue) =>
                                        setFormCompetenceGrade4(newValue)
                                      }
                                      aria-label="Always visible"
                                      defaultValue={0}
                                      getAriaValueText={valuetext}
                                      step={1}
                                      marks={marks}
                                      min={0}
                                      max={200}
                                      valueLabelDisplay="on"
                                    />
                                  </Box>
                                  <TextField
                                    sx={{
                                      marginLeft: 6,
                                      width: "80px",
                                    }}
                                    value={formCompetenceGrade4}
                                    onChange={(event) =>
                                      setFormCompetenceGrade4(
                                        event.target.value
                                      )
                                    }
                                    type="number"
                                    fullWidth
                                    margin="none"
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <TextField
                                  label="Feedback"
                                  value={formCompetenceFeedback4}
                                  onChange={(event) =>
                                    setFormCompetenceFeedback4(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um feedback"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                              <Box>
                                <TextField
                                  label="Recomendação"
                                  value={formCompetenceRecommendation4}
                                  onChange={(event) =>
                                    setFormCompetenceRecommendation4(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um recomendação"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Competencia 5 */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Competência 5
                              <Box maxWidth="100px">
                                <Typography sx={{ fontSize: "12px" }} mt={2}>
                                  Construção da proposta de intervenção.
                                </Typography>
                              </Box>
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Box textAlign="left">
                                <Typography mb={6}>
                                  Selecione uma nota de 0 a 200:
                                </Typography>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "280px" }}>
                                    <Slider
                                      value={formCompetenceGrade5}
                                      onChange={(event, newValue) =>
                                        setFormCompetenceGrade5(newValue)
                                      }
                                      aria-label="Always visible"
                                      defaultValue={0}
                                      getAriaValueText={valuetext}
                                      step={1}
                                      marks={marks}
                                      min={0}
                                      max={200}
                                      valueLabelDisplay="on"
                                    />
                                  </Box>
                                  <TextField
                                    sx={{
                                      marginLeft: 6,
                                      width: "80px",
                                    }}
                                    value={formCompetenceGrade5}
                                    onChange={(event) =>
                                      setFormCompetenceGrade5(
                                        event.target.value
                                      )
                                    }
                                    type="number"
                                    fullWidth
                                    margin="none"
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <TextField
                                  label="Feedback"
                                  value={formCompetenceFeedback5}
                                  onChange={(event) =>
                                    setFormCompetenceFeedback5(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um feedback"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                              <Box>
                                <TextField
                                  label="Recomendação"
                                  value={formCompetenceRecommendation5}
                                  onChange={(event) =>
                                    setFormCompetenceRecommendation5(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um recomendação"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Feedback */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Feedback geral
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <Box>
                                <TextField
                                  label="Feedback"
                                  value={formFeedback}
                                  onChange={(event) =>
                                    setFormFeedback(event.target.value)
                                  }
                                  fullWidth
                                  margin="none"
                                  multiline
                                  rows={4}
                                  helperText="Digite um feedback geral"
                                  sx={{
                                    marginY: 2,
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      my={4}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "18pt",
                        }}
                      >
                        Nota final:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18pt",
                          ml: 4,
                        }}
                      >
                        {parseInt(formCompetenceGrade1) +
                          parseInt(formCompetenceGrade2) +
                          parseInt(formCompetenceGrade3) +
                          parseInt(formCompetenceGrade4) +
                          parseInt(formCompetenceGrade5)}
                      </Typography>
                    </Box>
                    <Box textAlign="end" mt={2}>
                      <Button
                        onClick={handleChangeUpdateIA}
                        type="button"
                        color="error"
                        variant="contained"
                        startIcon={<ArrowForwardIcon />}
                      >
                        Corrigir automaticamente
                      </Button>
                      <Button
                        onClick={handleChangeUpdate}
                        type="button"
                        color="primary"
                        variant="contained"
                        startIcon={<EditIcon />}
                        sx={{
                          ml: 1,
                        }}
                      >
                        Atualizar nota
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Dialog open={openDialog} onClose={handleClickCloseUDialog}>
                <DialogTitle>{"Você tem certeza?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tem certeza de que deseja excluir "{formTitle}"? Registros
                    uma vez excluídos não podem ser recuperados.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Box mx={1} mb={1}>
                    <Button
                      onClick={handleClickCloseUDialog}
                      variant="text"
                      sx={{ marginRight: 2 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleChangeDelete}
                      autoFocus
                      color="error"
                      variant="outlined"
                    >
                      Sim, tenho certeza.
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
