/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Divider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

/* Icons */
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import { Show, DeleteCategoria, UpdateCategoria } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

export const DrawerCategoria = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  const [categoria, setCategoria] = useState(null);
  const [formId, setFormId] = useState("");
  const [formTitle, setFormTitle] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const handleClickCloseUDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenUDialog = () => {
    setOpenDialog(true);
  };

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const handleChangeDelete = async () => {
    await DeleteCategoria(formId)
      .catch((error) => {
        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      })
      .finally(() => {
        goList();
      });
  };

  const handleChangeUpdate = async () => {
    if (!formId || !formTitle) {
      alert("Por favor, preencha todos os campos antes de prosseguir.");
      return;
    }

    await UpdateCategoria(formId, formTitle)
      .catch((error) => {
        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      })
      .finally(() => {
        goList();
      });
  };

  // functions
  const formatDate = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  const goList = () => {
    window.location.reload();
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setCategoria(null);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const result = response;

              if (!unmounted) {
                setCategoria(result);

                setFormId(result.id);
                setFormTitle(result.title);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {categoria ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <Box textAlign="end" mb={4}>
                <Button
                  variant="outlined"
                  onClick={handleClickOpenUDialog}
                  type="button"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </Box>

              <SubTitlePage>{categoria.title}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* Informações */}
              <Box mb={4}>
                <SubTitlePage>Informações</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* ID */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {categoria.id}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Título */}
                      <StyledTableRow
                        sx={{
                          padding: 0,
                        }}
                      >
                        <StyledTableCellTitle align="left">
                          Título
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          <TextField
                            label="Título"
                            name="title"
                            value={formTitle}
                            onChange={(event) =>
                              setFormTitle(event.target.value)
                            }
                            fullWidth
                            margin="none"
                          />
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Cadastro */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Cadastrado em
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {formatDate(categoria.created_at)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box textAlign="end" mt={2} mb={4}>
                  <Button
                    onClick={handleChangeUpdate}
                    type="button"
                    color="primary"
                    variant="contained"
                    startIcon={<EditIcon />}
                  >
                    Atualizar
                  </Button>
                </Box>
              </Box>

              <Dialog open={openDialog} onClose={handleClickCloseUDialog}>
                <DialogTitle>{"Você tem certeza?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tem certeza de que deseja excluir "{formTitle}"? Registros
                    uma vez excluídos não podem ser recuperados.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Box mx={1} mb={1}>
                    <Button
                      onClick={handleClickCloseUDialog}
                      variant="text"
                      sx={{ marginRight: 2 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleChangeDelete}
                      autoFocus
                      color="error"
                      variant="outlined"
                    >
                      Sim, tenho certeza.
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
