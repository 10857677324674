/* Material */
import { createTheme } from "@mui/material";
import { indigo } from "@mui/material/colors";

import { ptBR } from "@material-ui/core/locale";

export const DarkTheme = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: indigo[400],
        dark: indigo[600],
        light: indigo[100],
      },
      secondary: {
        main: "#f9f9f9",
        dark: "#aeaeae",
        light: "#fafafa",
      },
      success: {
        main: "#28a745",
        light: "#53b86a",
        dark: "#1c7430",
      },
      table: {
        active: indigo[400],
        background: "#1e1e1e",
        selected: "#151515",
      },
      appBar: {
        main: "#212121",
        light: "#353535",
        dark: "",
      },
      drawer: {
        main: "#212121",
        light: "#353535",
        dark: "#1e1e1e",
      },
      background: {
        default: "#1e1e1e",
        paper: "#212121",
        document: "#212121",
      },
    },
  },
  ptBR
);
