import { redgiz, admin } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await admin.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const ListThemes = async (
  skip = 0,
  limit = 10,
  search = "",
  order_by = "created_at",
  order_direction = "desc"
) => {
  try {
    let response = await redgiz.get(
      `admin/essay_templates/?limit=${limit}&skip=${skip}&search=${search}&order_by=${order_by}&order_direction=${order_direction}`
    );

    const data = response.data;

    if (data) {
      return { result: data[0], qtd: data[1] };
    }

    return { result: [], qtd: 0 };
  } catch (error) {
    return error;
  }
};

export const ListUser = async (skip = 0, limit = 10, search = "") => {
  try {
    let response = await redgiz.get(
      `admin/users?limit=${limit}&skip=${skip}&search=${search}`
    );

    const data = response.data;

    if (data) {
      return { result: data[0], qtd: data[1] };
    }

    return { result: [], qtd: 0 };
  } catch (error) {
    return error;
  }
};

export const ShowTheme = async (id) => {
  try {
    let response = await redgiz.get(`admin/essay_templates/${id}`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowUser = async (id) => {
  try {
    let response = await redgiz.get(`admin/users/${id}`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateTheme = async (
  title,
  theme,
  description,
  category_id,
  examining_body_id,
  year
) => {
  try {
    let response = await redgiz.post("admin/essay_templates/", {
      title: title,
      theme: theme,
      description: description,
      category_id: category_id,
      examining_body_id: examining_body_id,
      year: year,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateTheme = async (
  id,
  title,
  theme,
  description,
  category_id,
  year
) => {
  try {
    let response = await redgiz.put(`admin/essay_templates/${id}`, {
      title,
      theme,
      description,
      category_id,
      year,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const DeleteTheme = async (id) => {
  try {
    let response = await redgiz.delete(`admin/essay_templates/${id}`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
