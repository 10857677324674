/* React */
import React, { useState } from "react";

/* Material */
import {
  Box,
  Button,
  Typography,
  TableRow as MuiTableRow,
} from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import {
  classesTable,
  StylesMuiTableCell as MuiTableCellBody,
} from "../../../../../components/app/Table";

// functions
const formatDate = (date) => {
  let dateString = new Date(date)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      // hour: "2-digit",
      // minute: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

export const TableRow = (props) => {
  // props
  const { row, openDrawer } = props;

  // functions
  function useHover() {
    const [hovering, setHovering] = useState(false);

    const onHoverProps = {
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
    };

    return [hovering, onHoverProps];
  }

  // states
  const [tdIsHovering, tdHoverProps] = useHover();

  return (
    <MuiTableRow
      {...tdHoverProps}
      onClick={openDrawer}
      sx={[
        {
          "&:hover td div.table-containerCellActive, &:hover td div.table-containerCell":
            {
              backgroundColor: (theme) => theme.palette.table.selected,
            },
        },
      ]}
    >
      {/* Título */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
        >
          {tdIsHovering ? (
            <Box
              sx={{
                marginRight: 1.75,
                height: "50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: (theme) => theme.spacing(0.75),
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="button"
                endIcon={<ArrowForwardIcon />}
              >
                Editar
              </Button>
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <Box component="div" className={classesTable.containerCellActive}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "280px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {row.title}
            </Typography>
          </Box>
        </Box>
      </MuiTableCellBody>

      {/* Inscrição */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 140,
          }}
        >
          {formatDate(row.created_at)}
        </Box>
      </MuiTableCellBody>
    </MuiTableRow>
  );
};
