/* Material */
import { styled, Drawer as MuiDrawer } from "@mui/material";

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    maxWidth: "560px",
    width: "100%",
    backgroundColor: `${theme.palette.background.paper} !important`,
    backgroundImage: "none !important",
  },
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
