/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import { Box, Button } from "@mui/material";

/* Components */
import { Sidebar } from "../../../components/app/Sidebar";
import { BoxPaper } from "../../../components/app/Box";
import { Scrollbar } from "../../../components/app/Scrollbar";

/* Components page */
import { DataTable } from "./DataTable";
import { DrawerAdd } from "./components/Drawer/Add";

/* Icons */
import AddIcon from "@mui/icons-material/Add";

/* Services */
import { getUser } from "./util";

export const Categoria = () => {
  // states
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);

  function openDrawer() {
    setOpen(!open);
  }

  const load = useCallback(async () => {
    try {
      let unmounted = false;

      (async function () {
        try {
          await getUser().then((response) => {
            let { result } = response;

            if (!unmounted) {
              setUser(result);
            }
          });
        } catch (error) {
          setUser(null);
          return null;
        }
      })();

      return () => {
        unmounted = true;
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  return (
    <Sidebar>
      <Box px={4}>
        {user.permissao === "1" || user.permissao === "2" ? (
          <Box
            mt={4}
            sx={{
              textAlign: {
                xs: "start",
                md: "end",
              },
            }}
          >
            <Button
              color="success"
              variant="contained"
              endIcon={<AddIcon />}
              onClick={openDrawer}
            >
              Novo
            </Button>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <BoxPaper>
          <Box minHeight="100vh">
            <Scrollbar>
              <DataTable />
            </Scrollbar>
          </Box>
        </BoxPaper>
      </Box>

      <DrawerAdd open={open} setOpen={setOpen} />
    </Sidebar>
  );
};
