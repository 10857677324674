/* React */
import React, {
  useEffect,
  // useState,
  useCallback,
} from "react";

/* Router */
import { useNavigate, useLocation } from "react-router-dom";

/* Material */
import {
  List,
  Typography,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

/* Icons */
import ListItemIcon from "@mui/material/ListItemIcon";
// import PersonIcon from "@mui/icons-material/Person";

/* Options */
import {
  itemsStart,
  itemsMenu,
  itemsSettings,
  itemsMenuPlayGiz,
  // itemsMenuStudio,
  itemsMenuRedGiz,
} from "../../items-menu";

/* Services */
// import { QtdUser } from "../../util";

/* Styles */
import { classes } from "../../styles";

/* Functions */
const activeClass = (current, path) => {
  let result = current.indexOf(path) !== -1;

  return result;
};

export const MenuDrawer = ({ open }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const [qtdUser, setQtdUser] = useState(0);

  const load = useCallback(async () => {
    try {
      // let unmounted = false;
      // (async function () {
      //   try {
      //     // await QtdUser().then((response) => {
      //     //   let qtd = response?.qtd;
      //     //   if (!unmounted && !isNaN(qtd.count)) {
      //     //     setQtdUser(parseInt(qtd.count));
      //     //   }
      //     // });
      //   } catch (error) {
      //     return null;
      //   }
      // })();
      // return () => {
      //   unmounted = true;
      // };
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  return (
    <React.Fragment>
      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsStart.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsStart.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />

      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsMenu.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsMenu.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />
{/*
      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsMenuPlayGiz.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsMenuPlayGiz.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />


      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsMenuStudio.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsMenuStudio.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />
*/}

      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsMenuRedGiz.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsMenuRedGiz.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />
      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsSettings.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsSettings.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>
    </React.Fragment>
  );
};
