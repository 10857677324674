import axios from "axios";

export const getLogs = async () => {
  try {
    let response = await axios.get("https://log-api.playgiz.com/page-view/all");
    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
