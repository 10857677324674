import React from "react";

/* Material */
import { Box, TextField, Autocomplete as MuiAutocomplete } from "@mui/material";

export function Autocomplete(props) {
  const {
    name,
    label,
    items,
    value,
    placeholder,
    error = null,
    onChange,
  } = props;

  return (
    <Box mb={"0.75rem"}>
      <MuiAutocomplete
        disablePortal
        name={name}
        placeholder={placeholder}
        value={value}
        options={items}
        noOptionsText="Não encontrado"
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={onChange}
        {...(error && {
          error: true,
          helperText: error,
        })}
      />
    </Box>
  );
}
