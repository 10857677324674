import React from "react";

/* Router */
import { Routes, Route, Navigate } from "react-router-dom";

/* OAuth pages */
import { Login } from "../../pages/OAuth/Login";
import { Logout } from "../../pages/OAuth/Logout";

/* Private pages */
import { Home } from "../../pages/Dashboard/Home";
import { Logs } from "../../pages/Dashboard/Logs";
import { LogsList } from "../../pages/Dashboard/LogsList";
import { Users } from "../../pages/Dashboard/Users";
import { Settings } from "../../pages/Dashboard/Settings";
import { DirectMail } from "../../pages/Dashboard/DirectMail";
import { Redacoes } from "../../pages/Dashboard/Redacoes";
import { RedGiz } from "../../pages/Dashboard/Redgiz1";
import { RedUsers } from "../../pages/Dashboard/RedUsers";
import { Theme } from "../../pages/Dashboard/Theme";
import { Categoria } from "../../pages/Dashboard/Categoria";
import { Banca } from "../../pages/Dashboard/Banca";

/* Components */
import { OAuthRoutes } from "../../components/routes/OAuthRoutes";
import { PrivateRoutes } from "../../components/routes/PrivateRoutes";

export const AppRoutes = () => {
  return (
    <Routes>
      {/* ================= OAUTH ROUTES ================= */}
      <Route element={<OAuthRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route path="/logout" element={<Logout />} />

      {/* ================= PRIVATE ROUTES ================= */}
      <Route element={<PrivateRoutes />}>
        <Route path="/inicio" element={<Home />} />
        <Route path="/usuarios" element={<Users />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/list-logs" element={<LogsList />} />

        <Route path="/redgiz-redacoes" element={<Redacoes />} />
        <Route path="/redgiz-usuarios" element={<RedUsers />} />
        <Route path="/redgiz-temas" element={<Theme />} />
        <Route path="/redgiz-categorias" element={<Categoria />} />
        <Route path="/redgiz-bancas" element={<Banca />} />

        <Route path="/mala-direta" element={<DirectMail />} />
        <Route path="/conta" element={<Settings />} />
      </Route>

      {/* ================= ERRORS ================= */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
