/* React */
import React, { useCallback, useEffect, useState } from "react";

/* Material */
import { Grid, Box, Button, Divider } from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import FormControl from "../../../../../../components/app/Form/FormControl";
import useForm from "../../../../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { SubTitlePage } from "../../../../../../components/app/Title";
import { Alert } from "../../../../../../components/app/Alert";

/* Functions */
import { CreateTheme } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import { ListBanca, ListCategoria } from "../../../../Categoria/util";

const initialValues = {
  title: "",
  theme: "",
  description: "",
  category_id: "",
  examining_body_id: "",
  year: "",
};

export const DrawerAdd = (props) => {
  // props
  const { open, setOpen } = props;

  const [categoria, setCategoria] = useState([]);
  const [banca, setBanca] = useState([]);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const isEmpty = (value) => {
    const result = value ? "" : "Preencha este campo.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      temp.title = isEmpty(fieldValues.title);
    }

    if ("theme" in fieldValues) {
      temp.theme = isEmpty(fieldValues.theme);
    }

    if ("description" in fieldValues) {
      temp.description = isEmpty(fieldValues.description);
    }

    if ("category_id" in fieldValues) {
      temp.category_id = isEmpty(fieldValues.category_id);
    }

    if ("examining_body_id" in fieldValues) {
      temp.examining_body_id = isEmpty(fieldValues.examining_body_id);
    }

    if ("year" in fieldValues) {
      temp.year = isEmpty(fieldValues.year);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          await CreateTheme(
            values.title,
            values.theme,
            values.description,
            values.category_id,
            values.examining_body_id,
            values.year
          )
            .catch((error) => {
              console.log(error);
              setMessageError("Ocorreu um erro ao salvar.");
              setOpenError(true);
            })
            .finally(() => {
              goList();
            });
        } else {
          setMessageError("Por favor, corrija os campos em vermelho.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("Ocorreu um erro inesperado na sessão.");
        setOpenError(true);
      }
    }, 100);
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const goList = () => {
    window.location.reload();
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setCategoria([]);
      setBanca([]);

      await ListCategoria(0, 99999, "")
        .then((response) => {
          const { result } = response;

          if (!unmounted) {
            setCategoria(result);
          }
        })
        .catch((error) => {
          setMessageError(error.message);
          setOpenError(true);
        });

      await ListBanca(0, 99999, "")
        .then((response) => {
          const { result } = response;

          if (!unmounted) {
            setBanca(result);
          }
        })
        .catch((error) => {
          setMessageError(error.message);
          setOpenError(true);
        });
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
            marginBottom: (theme) => theme.spacing(1.5),
          }}
        />

        <Box px={4} py={2}>
          <Box mb={4}>
            <SubTitlePage>Novo tema</SubTitlePage>

            <FormControl onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Título"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="Tema"
                    name="theme"
                    value={values.theme}
                    error={errors.theme}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.TextArea
                    label="Descrição"
                    name="description"
                    value={values.description}
                    error={errors.description}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Select
                    label="Categoria"
                    name="category_id"
                    nullValue={true}
                    items={categoria?.map((item) => ({
                      title: item.title,
                      value: item.id,
                    }))}
                    value={values.category_id}
                    error={errors.category_id}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Select
                    label="Banca"
                    name="examining_body_id"
                    nullValue={true}
                    items={banca?.map((item) => ({
                      title: item.title,
                      value: item.id,
                    }))}
                    value={values.examining_body_id}
                    error={errors.examining_body_id}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="Ano"
                    name="year"
                    type="number"
                    value={values.year}
                    error={errors.year}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  {openError ? (
                    <Box mb={2}>
                      <Alert message={messageError} open={openError} />
                    </Box>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  <Controls.Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    width="100%"
                    text="Salvar"
                    endIcon={<ArrowForwardIcon />}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
