import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

const REACT_APP_REDGIZ_API_URL = process.env.REACT_APP_REDGIZ_API_URL;
const REACT_APP_REDGIZ_ADMIN_KEY = process.env.REACT_APP_REDGIZ_ADMIN_KEY;

export const api = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const admin = axios.create({
  baseURL: REACT_APP_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const redgiz = axios.create({
  baseURL: REACT_APP_REDGIZ_API_URL,
  headers: {
    "Content-Type": "application/json",
    key: REACT_APP_REDGIZ_ADMIN_KEY,
  },
});
