import DashboardIcon from "@mui/icons-material/Dashboard";

import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import ClassIcon from "@mui/icons-material/Class";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";

export const itemsStart = {
  name: "DASHBOARD",
  items: [
    {
      path: "/inicio",
      icon: DashboardIcon,
      label: "Página Inicial",
    },
  ],
};

export const itemsMenu = {
  name: "PRINCIPAL",
  items: [
    {
      path: "/usuarios",
      icon: GroupIcon,
      label: "Usuários",
    },
    {
      path: "/logs",
      icon: PieChartIcon,
      label: "Logs",
    },
    {
      path: "/list-logs",
      icon: BarChartIcon,
      label: "Logs Detalhados",
    },
  ],
};

// export const itemsMenuPlayGiz = {
//   name: "Playgiz",
//   items: [],
// };

// export const itemsMenuStudio = {
//   name: "Studio",
//   items: [],
// };

export const itemsMenuRedGiz = {
  name: "REDGIZ",
  items: [
    {
      path: "/redgiz-redacoes",
      icon: AutoStoriesIcon,
      label: "Redações",
    },
    {
      path: "/redgiz-usuarios",
      icon: GroupIcon,
      label: "Usuários",
    },
    {
      path: "/redgiz-temas",
      icon: ClassIcon,
      label: "Temas de redação",
    },
    {
      path: "/redgiz-categorias",
      icon: BookmarksIcon,
      label: "Categorias",
    },
    {
      path: "/redgiz-bancas",
      icon: CollectionsBookmarkIcon,
      label: "Bancas",
    },
  ],
};

export const itemsSettings = {
  name: "FERRAMENTAS",
  items: [
    {
      path: "/mala-direta",
      icon: EmailIcon,
      label: "Mala Direta",
    },
    {
      path: "/conta",
      icon: SettingsIcon,
      label: "Configurações",
    },
    {
      path: "/logout",
      icon: LogoutIcon,
      label: "Sair",
    },
  ],
};
