/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Divider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

/* Icons */
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import { ShowTheme, DeleteTheme, UpdateTheme } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";
import {
  CreateSupportingTexts,
  DeleteThemeSupportingTexts,
  ListCategoria,
  ListSupportingTexts,
} from "../../../../Categoria/util";

export const DrawerTheme = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  const [theme, setTheme] = useState(null);
  const [categoria, setCategoria] = useState([]);
  const [formId, setFormId] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formTheme, setFormTheme] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formCategoryId, setFormCategoryId] = useState("");
  const [formYear, setFormYear] = useState("");

  const [supportingTexts, setSupportingTexts] = useState(null);
  const [openFormSupport, setOpenFormSupport] = useState(false);
  const [formSupportTitle, setFormSupportTitle] = useState("");
  const [formSupportContent, setFormSupportContent] = useState("");
  const [formSupportExternalUrl, setFormSupportExternalUrl] = useState("");
  const [formSupportType, setFormSupportType] = useState("TEXT");
  const [formSupportReference, setFormSupportReference] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const [expandedAccordionSupport, setExpandedAccordionSupport] =
    useState(false);

  const handleChangeAccordionSupport = (panel) => (event, isExpanded) => {
    setExpandedAccordionSupport(isExpanded ? panel : false);
  };

  const handleClickCloseUDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenUDialog = () => {
    setOpenDialog(true);
  };

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const handleChangeDelete = async () => {
    await DeleteTheme(formId)
      .catch((error) => {
        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      })
      .finally(() => {
        goList();
      });
  };

  const handleChangeUpdate = async () => {
    if (
      !formId ||
      !formTitle ||
      !formTheme ||
      !formDescription ||
      !formCategoryId ||
      !formYear
    ) {
      alert("Por favor, preencha todos os campos antes de prosseguir.");
      return;
    }

    await UpdateTheme(
      formId,
      formTitle,
      formTheme,
      formDescription,
      formCategoryId,
      formYear
    )
      .catch((error) => {
        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      })
      .finally(() => {
        goList();
      });
  };

  // functions
  const formatDate = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  const goList = () => {
    window.location.reload();
  };

  const loadSupportingTexts = async (id) => {
    setSupportingTexts(null);

    try {
      await ListSupportingTexts(id, 0, 99999, "")
        .then((response) => {
          const result = response;

          console.log(response);

          setSupportingTexts(result?.supporting_texts);
        })
        .catch((error) => {
          setMessageError(error.message);
          setOpenError(true);
        });
    } catch (error) {
      // internal error
      setMessageError("Ocorreu um erro inesperado na sessão.");
      setOpenError(true);
    }
  };

  const newSupportingTexts = async () => {
    if (
      !formSupportTitle ||
      (formSupportType === "TEXT" && !formSupportContent) ||
      (formSupportType === "IMAGE" && !formSupportExternalUrl) ||
      !formSupportReference
    ) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    try {
      await CreateSupportingTexts(
        formId,
        formSupportContent,
        formSupportTitle,
        formSupportExternalUrl,
        formSupportType,
        formSupportReference
      )
        .catch((error) => {
          console.log(error);
          setMessageError("Ocorreu um erro ao salvar.");
          setOpenError(true);
        })
        .finally(() => {
          setFormSupportTitle("");
          setFormSupportContent("");
          setFormSupportExternalUrl("");
          setFormSupportType("TEXT");
          setFormSupportReference("");

          setOpenFormSupport(false);
          loadSupportingTexts(formId);
        });
    } catch (error) {
      // internal error
      setMessageError("Ocorreu um erro inesperado na sessão.");
      setOpenError(true);
    }
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setTheme(null);
      setSupportingTexts(null);
      setCategoria([]);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await ShowTheme(id)
            .then(async (response) => {
              const result = response;

              if (!unmounted) {
                setTheme(result);

                setFormId(result.id);
                setFormTitle(result.title);
                setFormTheme(result.theme);
                setFormDescription(result.description);
                setFormCategoryId(result.category_id);
                setFormYear(result.year);

                loadSupportingTexts(result.id);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });

          await ListCategoria(0, 99999, "")
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setCategoria(result);
              }
            })
            .catch((error) => {
              setMessageError(error.message);
              setOpenError(true);
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            maxWidth: "95% !important",
            width: "100%",
            backgroundImage: "none !important",
          },
        }}
      >
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {theme ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <Box textAlign="end" mb={4}>
                <Button
                  variant="outlined"
                  onClick={handleClickOpenUDialog}
                  type="button"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </Box>

              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <SubTitlePage>{theme.title}</SubTitlePage>

                  <Divider
                    sx={{
                      marginBottom: 4,
                    }}
                  />

                  {/* Informações */}
                  <Box mb={4}>
                    <SubTitlePage>Informações</SubTitlePage>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }}>
                        <TableBody>
                          {/* ID */}
                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              ID
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              {theme.id}
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Título */}
                          <StyledTableRow
                            sx={{
                              padding: 0,
                            }}
                          >
                            <StyledTableCellTitle align="left">
                              Título
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              <TextField
                                label="Título"
                                name="title"
                                value={formTitle}
                                onChange={(event) =>
                                  setFormTitle(event.target.value)
                                }
                                fullWidth
                                margin="none"
                              />
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              Tema
                            </StyledTableCellTitle>
                            <StyledTableCell align="right">
                              <TextField
                                label="Tema"
                                name="theme"
                                value={formTheme}
                                onChange={(event) =>
                                  setFormTheme(event.target.value)
                                }
                                fullWidth
                                margin="none"
                              />
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              Descrição
                            </StyledTableCellTitle>
                            <StyledTableCell align="right">
                              <TextField
                                label="Descrição"
                                name="description"
                                value={formDescription}
                                onChange={(event) =>
                                  setFormDescription(event.target.value)
                                }
                                fullWidth
                                margin="none"
                                multiline
                                rows={4}
                              />
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              Categoria
                            </StyledTableCellTitle>
                            <StyledTableCell align="right">
                              <Select
                                sx={{
                                  textAlign: "start",
                                }}
                                label="Categoria"
                                name="category_id"
                                value={formCategoryId}
                                onChange={(event) =>
                                  setFormCategoryId(event.target.value)
                                }
                                fullWidth
                              >
                                {categoria?.map((category) => (
                                  <MenuItem
                                    key={category.id}
                                    value={category.id}
                                  >
                                    {category.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              Ano
                            </StyledTableCellTitle>
                            <StyledTableCell align="right">
                              <TextField
                                label="Ano"
                                name="year"
                                type="number"
                                value={formYear}
                                onChange={(event) =>
                                  setFormYear(event.target.value)
                                }
                                fullWidth
                                margin="none"
                              />
                            </StyledTableCell>
                          </StyledTableRow>

                          {/* Cadastro */}
                          <StyledTableRow>
                            <StyledTableCellTitle align="left">
                              Cadastrado em
                            </StyledTableCellTitle>

                            <StyledTableCell align="right">
                              {formatDate(theme.created_at)}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box textAlign="end" mt={2} mb={4}>
                      <Button
                        onClick={handleChangeUpdate}
                        type="button"
                        color="primary"
                        variant="contained"
                        startIcon={<EditIcon />}
                      >
                        Atualizar
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SubTitlePage>Proposta de redação</SubTitlePage>

                  <Divider
                    sx={{
                      marginBottom: 4,
                    }}
                  />

                  {/* Informações */}
                  {openFormSupport ? (
                    <>
                      <Box mb={4}>
                        <Box
                          component={Paper}
                          textAlign="end"
                          mb={4}
                          sx={{
                            textAlign: "start",
                            borderRadius: 4,
                            p: 4,
                          }}
                        >
                          <SubTitlePage>Nova proposta</SubTitlePage>

                          <Box
                            mt={2}
                            sx={{
                              width: "100%",
                            }}
                          >
                            {/* Campo Título */}
                            <TextField
                              label="Título"
                              name="title"
                              value={formSupportTitle}
                              onChange={(event) =>
                                setFormSupportTitle(event.target.value)
                              }
                              fullWidth
                              margin="none"
                              sx={{
                                marginBottom: 4,
                              }}
                            />

                            <Select
                              sx={{
                                textAlign: "start",
                                marginBottom: 4,
                              }}
                              label="Tipo"
                              name="type"
                              value={formSupportType}
                              onChange={(event) =>
                                setFormSupportType(event.target.value)
                              }
                              fullWidth
                            >
                              <MenuItem value="TEXT">Texto</MenuItem>
                              <MenuItem value="IMAGE">Imagem</MenuItem>
                            </Select>

                            {/* Campo Content - aparece somente quando formSupportType === "TEXT" */}
                            {formSupportType === "TEXT" && (
                              <TextField
                                label="Conteúdo"
                                name="content"
                                value={formSupportContent}
                                onChange={(event) =>
                                  setFormSupportContent(event.target.value)
                                }
                                fullWidth
                                margin="none"
                                multiline
                                rows={4}
                                helperText="Digite uma proposta de redação em texto"
                                sx={{
                                  marginBottom: 4,
                                }}
                              />
                            )}

                            {/* Campo External URL - aparece somente quando formSupportType === "IMAGE" */}
                            {formSupportType === "IMAGE" && (
                              <>
                                <TextField
                                  label="URL Externa"
                                  name="external_url"
                                  value={formSupportExternalUrl}
                                  onChange={(event) =>
                                    setFormSupportExternalUrl(
                                      event.target.value
                                    )
                                  }
                                  fullWidth
                                  margin="none"
                                  helperText="Insira o link de uma IMAGEM para ser usada como proposta de redação"
                                  sx={{
                                    marginBottom: 4,
                                  }}
                                />

                                {formSupportExternalUrl && (
                                  <Box
                                    sx={{
                                      height: "200px",
                                      width: "200px",
                                      backgroundColor: "#000000",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      my={1}
                                      sx={{
                                        height: "auto",
                                        width: "200px",
                                      }}
                                      component="img"
                                      src={formSupportExternalUrl}
                                      alt="Imagem"
                                    />
                                  </Box>
                                )}
                              </>
                            )}

                            {/* Campo Reference */}
                            <TextField
                              label="Referência"
                              name="reference"
                              value={formSupportReference}
                              onChange={(event) =>
                                setFormSupportReference(event.target.value)
                              }
                              fullWidth
                              margin="normal"
                            />
                          </Box>

                          <Box textAlign="end" mt={2}>
                            <Button
                              onClick={newSupportingTexts}
                              type="button"
                              color="primary"
                              variant="contained"
                              startIcon={<AddIcon />}
                            >
                              Salvar Proposta
                            </Button>
                            <Button
                              onClick={() => {
                                setOpenFormSupport(false);
                              }}
                              type="button"
                              color="error"
                              variant="outlined"
                              sx={{
                                ml: 1,
                              }}
                            >
                              Cancelar
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box mb={4}>
                        <Box textAlign="end" mt={2} mb={4}>
                          <Button
                            onClick={() => {
                              setOpenFormSupport(true);
                            }}
                            type="button"
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                          >
                            Adicionar Proposta
                          </Button>
                        </Box>

                        {supportingTexts ? (
                          <React.Fragment>
                            {supportingTexts.length > 0 ? (
                              <React.Fragment>
                                <Box component={Paper} p={2}>
                                  {supportingTexts.map((item, index) => (
                                    <Accordion
                                      key={item.id}
                                      expanded={
                                        expandedAccordionSupport ===
                                        `panel${index}`
                                      }
                                      onChange={handleChangeAccordionSupport(
                                        `panel${index}`
                                      )}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}bh-content`}
                                        id={`panel${index}bh-header`}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item.title}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box textAlign="end" mb={4} mt={2}>
                                          <Button
                                            variant="outlined"
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  "Você tem certeza que deseja excluir este item?"
                                                )
                                              ) {
                                                const deleteItem = async () => {
                                                  await DeleteThemeSupportingTexts(
                                                    item.id
                                                  )
                                                    .catch((error) => {
                                                      console.log(error);
                                                      setMessageError(
                                                        error.message
                                                      );
                                                      setOpenError(true);
                                                    })
                                                    .finally(() => {
                                                      setOpenFormSupport(false);
                                                      loadSupportingTexts(
                                                        formId
                                                      );
                                                    });
                                                };

                                                deleteItem();
                                              }
                                            }}
                                            type="button"
                                            color="error"
                                            startIcon={<DeleteIcon />}
                                          >
                                            Excluir
                                          </Button>
                                        </Box>

                                        <TableContainer component={Paper}>
                                          <Table sx={{ minWidth: "100%" }}>
                                            <TableBody>
                                              {/* Tipo */}
                                              <StyledTableRow>
                                                <StyledTableCellTitle align="left">
                                                  Tipo
                                                </StyledTableCellTitle>

                                                <StyledTableCell align="right">
                                                  {item.type === "TEXT" &&
                                                    "Conteúdo em texto"}
                                                  {item.type === "IMAGE" &&
                                                    "URL externa"}
                                                </StyledTableCell>
                                              </StyledTableRow>

                                              {item.type === "TEXT" && (
                                                <StyledTableRow>
                                                  <StyledTableCellTitle align="left">
                                                    Conteúdo
                                                  </StyledTableCellTitle>

                                                  <StyledTableCell align="right">
                                                    <Typography
                                                      sx={{
                                                        textAlign: "justify",
                                                        fontSize: "10pt",
                                                      }}
                                                    >
                                                      {item.content}
                                                    </Typography>
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              )}

                                              {item.type === "IMAGE" && (
                                                <StyledTableRow>
                                                  <StyledTableCellTitle align="left">
                                                    URL externa
                                                  </StyledTableCellTitle>

                                                  <StyledTableCell align="right">
                                                    <Box
                                                      sx={{
                                                        height: "200px",
                                                        width: "200px",
                                                        backgroundColor:
                                                          "#000000",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <Box
                                                        my={1}
                                                        sx={{
                                                          height: "auto",
                                                          width: "200px",
                                                        }}
                                                        component="img"
                                                        src={item.external_url}
                                                        alt="Imagem"
                                                      />
                                                    </Box>
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              )}

                                              {/* Referência */}
                                              <StyledTableRow>
                                                <StyledTableCellTitle align="left">
                                                  Referência
                                                </StyledTableCellTitle>

                                                <StyledTableCell align="right">
                                                  <Typography>
                                                    {item.reference}
                                                  </Typography>
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                                </Box>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Box textAlign="center" mt={4} mb={5} px={2}>
                                  <AutoStoriesIcon
                                    fontSize="large"
                                    color="secondary"
                                  />
                                  <Typography
                                    component="div"
                                    variant="h6"
                                    fontWeight={600}
                                    color="secondary"
                                    mt={1}
                                  >
                                    Nenhum registro encontrado.
                                  </Typography>
                                </Box>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <Box my={5} textAlign="center">
                            <CircularProgress color="secondary" />
                          </Box>
                        )}
                      </Box>

                      <Box mt={4}></Box>
                    </>
                  )}
                </Grid>
              </Grid>

              <Dialog open={openDialog} onClose={handleClickCloseUDialog}>
                <DialogTitle>{"Você tem certeza?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tem certeza de que deseja excluir "{formTitle}"? Registros
                    uma vez excluídos não podem ser recuperados.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Box mx={1} mb={1}>
                    <Button
                      onClick={handleClickCloseUDialog}
                      variant="text"
                      sx={{ marginRight: 2 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleChangeDelete}
                      autoFocus
                      color="error"
                      variant="outlined"
                    >
                      Sim, tenho certeza.
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
